import { HTTP } from '../services';
import buildQuery from '@/helpers/url';

export default new class LeadService {
  async paginate(page, search, sortBy, sortOrder, country, hubspotOwnerId, hubspotUnSyncedOnly) {
    let params = {
      page: page || 1,
      search: search || '',
      sort_by: this.getSortOrderField(sortBy) || '',
      sort_order: this.getSortOrderType(sortOrder) || '',
      country,
      hubspot_owner_id: hubspotOwnerId,
      hubspot_sync_failed_only: hubspotUnSyncedOnly ? 1 : 0,
    };

    let data = await HTTP.get('/v1/leads?' + buildQuery(params));
    return data;
  }

  async paginateLeadDuplicates(page, search, sortBy, sortOrder, country) {
    let params = {
      page: page || 1,
      search: search || '',
      sort_by: this.getSortOrderField(sortBy) || '',
      sort_order: this.getSortOrderType(sortOrder) || '',
      country,
    };

    let data = await HTTP.get('/v1/leads/duplicates?' + buildQuery(params));
    return data;
  }

  async getLeadDuplicateDetails(leadId) {
    return await HTTP.get('/v1/leads/' + leadId + '/duplicates');
  }

  async saveDuplicates(leadId, data) {
    return await HTTP.post('/v1/leads/' + leadId + '/duplicates', data);
  }

  getLeadById(data) {
    return HTTP.get('/v1/leads/' + data.id);
  }

  getVenueSpecialists() {
    return HTTP.get('/v1/venue_specialists');
  }

  changeVenueSpecialistAttribute(venueSpecilaitId, attribute, value) {
    let data = {
      attribute,
      value,
    };
    return HTTP.put('/v1/venue_specialists/' + venueSpecilaitId + '/update', data);
  }

  changeVenueSpecialistSetting(attribute, value) {
    let data = {
      attribute,
      value,
    };
    return HTTP.put('/v1/venue_specialists/global/update', data);
  }

  getVenueSpecialistSetting() {
    return HTTP.get('/v1/venue_specialists/settings');
  }

  createLead(data) {
    return HTTP.post('/v1/leads', data);
  }

  updateLead(data, leadId) {
    return HTTP.put('/v1/leads/' + leadId, data);
  }
  
  updateHubspotOwner(data, leadId) {
    return HTTP.put('/v1/leads/' + leadId +'/hubspotowner', data);
  }

  requeue(leadId) {
    return HTTP.post('/v1/leads/' + leadId + '/requeue');
  }

  resubscribe(leadId) {
    return HTTP.post('/v1/leads/' + leadId + '/resubscribe');
  }

  deleteLead(leadId) {
    return HTTP.delete('/v1/leads/' + leadId);
  }

  getLeadDistribution(date, country) {
    return HTTP.get('/v1/leads/leads_distribution?date=' + date + "&country=" + country);
  }

  getSortOrderField(sort) {
    let translation = {
      id: 'id',
      name: 'first_name',
      estimatedBudget: 'estimated_budget',
      eventDetails: 'event_details',
      estimatedGuests: 'estimated_guests',
      eventType: 'event_type',
      createdDate: 'event_date',
    };
    return translation[sort] || '';
  }

  getSortOrderType(type) {
    let translation = {
      ascending: 'asc',
      descending: 'desc',
    };
    return translation[type] || '';
  }

  getEmails(leadId) {
    return HTTP.get('/v1/email-log/' + leadId);
  }

  queueLeadsToHs(leads) {
    return HTTP.post('/v1/leads/requeue-to-hs', leads);
  }

  async getLeadHubspotSyncStatus(leadId) {
    let data = await HTTP.get('/v1/leads/' + leadId + '/hubspot-sync-status');
    return data.data;
  }

  async saveAndResyncToHubspot(leadId, data) {
    console.log(data);
    let response = await HTTP.post('/v1/leads/' + leadId + '/save-and-sync-to-hubspot', data);
    return response.data;
  }

}();
