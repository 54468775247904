import { LeadService } from '@/services';
import LeadEventService from '@/services/lead-event.service';
import Vue from 'vue';

export const leads = {
  namespaced: true,
  state: {
    leads: [],
    emails: [],
    leadEvents: [],
  },
  actions: {
    getSingle({ commit, dispatch }, data) {
      return LeadService.getLeadById(data)
        .then((lead) => {
          lead = lead.data;
          commit('updateSingleLead', lead);
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
    createLead({ commit }, data) {
      return LeadService.createLead(data).then((res) => {
        return res.data;
      });
    },
    getEmails({ commit, dispatch }, leadId) {
      return LeadService.getEmails(leadId)
        .then((results) => {
          let emails = results.data;
          commit('updateEmails', {
            emails,
            leadId,
          });
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
    getLeadEvents({ commit, dispatch }, leadId) {
      return LeadEventService.getLeadEventByLeadId(leadId)
        .then((results) => {
          let leadEvents = results.data;
          commit('updateLeadEvents', {
            leadEvents,
            leadId,
          });
        })
        .catch((error) => {
          dispatch('alert/error', error, { root: true });
        });
    },
    deleteLead({ commit }, leadId) {
      return LeadService.deleteLead(leadId).then((res) => {
        commit('removeSingleLead', leadId);
      });
    },
  },
  mutations: {
    removeSingleLead(state, leadId) {
      state.leads = state.leads.filter(function(item) {
        return parseInt(item.id) !== parseInt(leadId);
      });
    },
    updateSingleLead(state, lead) {
      state.leads = state.leads.filter(function(item) {
        return parseInt(item.id) !== parseInt(lead.id);
      });
      state.leads.push(lead);
    },
    updateEmails(state, data) {
      Vue.set(state.emails, data.leadId, data.emails);
    },
    updateLeadEvents(state, data) {
      Vue.set(state.leadEvents, data.leadId, data.leadEvents);
    },
  },
  getters: {
    getLeadById: (state) => (id) => {
      let lead = state.leads.find((lead) => parseInt(lead.id) === parseInt(id));
      return lead || {};
    },
    getEmailsByLeadId: (state) => (id) => {
      return typeof state.emails[id] !== 'undefined' ? state.emails[id] : [];
    },
    getLeadEventsByLeadId: (state) => (id) => {
      return typeof state.leadEvents[id] !== 'undefined' ? state.leadEvents[id] : [];
    },
  },
};
